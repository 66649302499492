<template>
  <transition name="button" :appear="true" :css="false" @enter="onEnter">
    <button
      class="t-btn-primary common-button"
      :class="classBtn"
      @click="onClick"
    >
      <div class="background-secondary" />
      <div class="background" />
      <div class="content">
        <span class="label">
          <component :is="`${icon}-icon`" v-if="!!icon" :class="icon" />
          {{ label }}
        </span>
      </div>
    </button>
  </transition>
</template>

<script>
import { useRouter } from 'vue-router'
import anime from 'animejs/lib/anime.es.js'

import ReplayIcon from '@/assets/svg/replay.svg'
import ShareIcon from '@/assets/svg/share.svg'
import { computed } from '@vue/runtime-core'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'
import { useGtm } from 'vue-gtm'

export default {
  components: {
    ReplayIcon,
    ShareIcon
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    medium: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    appear: {
      type: Number,
      default: 0
    },
    sound: {
      type: Boolean,
      default: false
    },
    tracking: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['click'],
  setup(props, ctx) {
    const gtm = useGtm()
    const router = useRouter()
    const howler = useHowler()

    const sound = howler.$getSound('click', false, {
      srcs: [FX_URL + 'click.mp3'],
      preload: true,
      preloadCallback: () => {},
      html5: false
    })

    const classBtn = computed(() => {
      const c = {
        disabled: props.disabled,
        't-btn-primary--small': props.small,
        't-btn-primary--medium': props.medium,
        't-btn-outline': props.outline,
        't-btn-icon': !!props.icon
      }

      c[`t-btn--${props.color}`] = true
      return c
    })

    const onClick = e => {
      if (props.disabled) return
      ctx.emit('click')
      const el = e.srcElement
      const bg = el.querySelector('.background-secondary')

      sound.play()

      anime({
        targets: bg,
        duration: 467,
        translateX: ['-50%', '-50%'],
        scaleX: [1, 1.12],
        scaleY: [1, 1.33],
        opacity: [1, 0],
        easing: 'cubicBezier(0.33, 0.00, 0.67, 1.00)'
      })

      if (props.to.length > 0) {
        if (props.to.startsWith('https') || props.to.startsWith('http')) {
          window.open(props.to)
        } else
          router.push({
            path: props.to
          })
      }

      if (props.tracking && props.tracking.event) {
        gtm.trackEvent({ ...props.tracking })
      }
    }

    const onEnter = el => {
      if (props.disabled) return
      const bg = el.querySelector('.background')
      const label = el.querySelector('.label')

      anime({
        targets: bg,
        duration: 900,
        delay: props.appear,
        width: [0, '100%'],
        borderWidth: [0, props.small || props.outline ? 1 : 2],
        easing: 'cubicBezier(0.16, 0, 0, 1)'
      })
      anime({
        targets: label,
        duration: 833,
        delay: props.appear + 100,
        translateY: [45, 0],
        easing: 'cubicBezier(0, 0, 0.1, 1)'
      })
    }

    return { onClick, onEnter, classBtn }
  }
}
</script>
