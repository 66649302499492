<template>
  <form @submit.prevent="checkForm">
    <div>
      <div class="age-gate-form-fields">
        <div class="age-gate-form-field age-gate-form-field--short">
          <select v-model="month" aria-label="Month">
            <option
              v-for="(f, i) in Array(12).fill()"
              :key="`month${i}`"
              :value="i + 1"
            >
              {{ i + 1 }}
            </option>
          </select>
          <div class="age-gate-form-field-value">
            {{ month || 'MM' }}
          </div>
        </div>
        <div class="age-gate-form-field age-gate-form-field--short">
          <select v-model="day" aria-label="Day">
            <option
              v-for="(f, i) in Array(31).fill()"
              :key="`day${i}`"
              :value="i + 1"
            >
              {{ i + 1 }}
            </option>
          </select>
          <div class="age-gate-form-field-value">
            {{ day || 'DD' }}
          </div>
        </div>
        <div class="age-gate-form-field age-gate-form-field--long">
          <select v-model="year" aria-label="Year">
            <option
              v-for="(f, i) in Array(100).fill()"
              :key="`year${i}`"
              :value="2021 - i"
            >
              {{ 2021 - i }}
            </option>
          </select>
          <div class="age-gate-form-field-value">
            {{ year || 'YYYY' }}
          </div>
        </div>
      </div>
      <div v-if="formError" class="age-gate-form-error">
        Selected date is invalid
      </div>

      <div class="age-gate-form-submit-wrapper">
        <div class="inner">
          <common-button :label="t('AgeGate.cta-ag')" disabled />
          <common-button
            v-if="day && month && year"
            type="submit"
            :label="t('AgeGate.cta-ag')"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { inject, ref, computed } from 'vue'
import CommonButton from '@/components/CommonButton.vue'
import { useRouter } from 'vue-router'
import { isDebugMode } from '@/hooks/global'

import moment from 'moment'
import Cookies from 'js-cookie'
import { useGtm } from 'vue-gtm'

export default {
  components: {
    'common-button': CommonButton
  },

  setup() {
    const t = inject('$t')
    const router = useRouter()
    const gtm = useGtm()

    const formError = ref(false)
    const day = ref(isDebugMode() ? '19' : '')
    const month = ref(isDebugMode() ? '12' : '')
    const year = ref(isDebugMode() ? '1991' : '')

    const isOver21 = computed(() => {
      if (day.value === '' || month.value === '' || year.value === '') return

      const birthdate = moment(
        `${year.value}-${month.value}-${day.value}`,
        'YYYY-MM-DD'
      )
      if (!birthdate.isValid()) return

      return birthdate.isBefore(moment().subtract(21, 'years'))
    })

    const checkForm = () => {
      formError.value = false
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'agegate-submit',
        eventLabel: '',
        eventValue: isOver21.value ? 1 : 0,
        eventCategory: 'cta'
      })
      if (!isOver21.value) {
        formError.value = true
        window.open('https://www.responsibility.org/', '_blank')
      } else {
        Cookies.set('over21', isOver21.value, { expires: 365 })

        if (isOver21.value) {
          setTimeout(() => {
            router.push({
              name: 'GameIntro'
            })
          }, 467)
        }
      }
    }

    return {
      t,
      day,
      month,
      year,
      checkForm,
      isOver21,
      formError
    }
  }
}
</script>

<style lang="scss">
.age-gate-form {
  &-fields {
    display: flex;
    margin: 0 -15px;

    border-top: 1px solid #f28e6c;
    border-bottom: 1px solid #f28e6c;

    @include respond-to(xs) {
      margin: 0 -7px;
    }
  }

  &-field {
    position: relative;

    &:not(:last-child) {
      border-right: 1px solid #f28e6c;
    }

    &--short {
      flex: 2;
    }

    &--long {
      flex: 3;
    }

    select {
      width: 100%;
      height: 95px;
      opacity: 0;

      // iPhone 5/SE
      @include respond-to(xs) {
        height: 66px;
      }
    }
  }

  &-field-value {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    color: #8c2534;

    font-family: font(heading2);
    font-size: fontSizeHeading(h3);

    // iPhone 5/SE
    @include respond-to(xs) {
      font-size: fontSize(xl);
    }
  }

  &-error {
    margin: 8px auto;

    color: #e93a32;

    text-align: center;
  }

  &-submit-wrapper {
    position: relative;
    margin-top: 33px;
    display: flex;
    justify-content: center;
    text-align: center;

    .inner {
      width: 220px;
      height: 72px;
      position: relative;

      @include respond-to(xs) {
        width: 159px;
      }
    }

    .common-button {
      position: absolute;
      top: 0px;
      left: 0;
    }

    // iPhone 5/SE
    @include respond-to(xs) {
      margin-top: 16px;

      button {
        padding-top: 16px;
        padding-bottom: 16px;

        font-size: fontSizeHeading(h6);
      }
    }
  }
}
</style>
