const rules = [
  { maxScore: 1, entries: 1},
  { maxScore: 500, entries: 5},
  { maxScore: 1000, entries: 10},
  { maxScore: 1500, entries: 15},
  { maxScore: 2000, entries: 20},
  { maxScore: 2500, entries: 25},
  { maxScore: 3000, entries: 30},
  { maxScore: 3200, entries: 32},
  { maxScore: 3400, entries: 34},
  { maxScore: 3600, entries: 36},
  { maxScore: 3800, entries: 38},
  { maxScore: 4000, entries: 40},
  { maxScore: 4200, entries: 42},
  { maxScore: 4400, entries: 44},
  { maxScore: 4600, entries: 46},
  { maxScore: 4800, entries: 48},
  { maxScore: 5000, entries: 50},
]

export function getEntries(score: number) {
  let hardness = 1
  let entries = 0

  for (let index = 0; index < rules.length; index++) {
    const currentRule = rules[index];
    const prevRuleScore = index > 0 ? rules[index - 1].maxScore : 0;
    const isLast = index === rules.length - 1

    if (score >= (prevRuleScore * hardness) + 1 && score <= currentRule.maxScore * hardness) {
      entries = currentRule.entries
    } else if (isLast && score > currentRule.maxScore * hardness) {
      entries = currentRule.entries
    }
  }

  return entries
}