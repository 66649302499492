import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import { checkIsMobile } from '../hooks/global'
import Cookies from 'js-cookie'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      const isMobile = checkIsMobile()
      const cookie = Cookies.get('over21')
      if (isMobile && cookie === 'true') {
        next({ name: 'GameIntro', query: to.query})
      } else {
        next()
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/game-intro',
    name: 'GameIntro',
    component: () => import(/* webpackChunkName: "about" */ '../views/GameIntro.vue')
  },
  {
    path: '/game',
    name: 'Game',
    meta: {
      landscape: true
    },
    component: () => import(/* webpackChunkName: "game" */ '../views/Game.vue')
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Leaderboard.vue')
  },
  {
    path: '/sharing',
    name: 'Sharing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sharing.vue')
  },
  {
    path: '/submission',
    name: 'Submission',
    component: () => import(/* webpackChunkName: "about" */ '../views/Submission.vue')
  },
  {
    path: '/score',
    name: 'Score',
    meta: {
      landscape: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/EndScore.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('global/setMenu', false)
  next()
})

export default router
