
import { inject, nextTick, onActivated, onMounted, ref } from 'vue'
import { checkIsMobile } from '../hooks/global'
import { useRouter } from 'vue-router'
import HomepageFooter from '@/components/HomepageFooter.vue'
import AgeGateForm from '@/components/AgeGateForm.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import CheckIcon from '@/assets/svg/check.svg'
import Cookies from 'js-cookie'
import anime from 'animejs/lib/anime.es.js'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'
import { useGtm } from 'vue-gtm'

// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    'home-footer': HomepageFooter,
    'age-gate-form': AgeGateForm,
    SiteHeader,
    CheckIcon
  },

  setup() {
    const gtm = useGtm()
    const t = inject('$t')
    const router = useRouter()
    const howler = useHowler()
    const isMobile = ref(false)
    const cookiesAccepted = ref(false)
    const enableCookiesBanner = ref(false)

    const root = ref(null)
    const background = ref(null)
    const video = ref(null)

    const hasLeaveTransition = ref(true)

    const soundTransition = howler.$getSound('transition-home', false, {
      srcs: [FX_URL + 'transition-home.mp3'],
      preload: true,
      preloadCallback: () => {},
      html5: false
    })

    onActivated(() => {
      isMobile.value = checkIsMobile()
      cookiesAccepted.value = !!Cookies.get('cookiesAccepted')

      nextTick(enterAnimation)

      const cookie = Cookies.get('over21')
      if (cookie === 'true' && isMobile.value) {
        hasLeaveTransition.value = false
        router.push({
          name: 'GameIntro'
        })
      } else {
        if (!isMobile.value) {
          gtm.trackEvent({
            event: 'vpv',
            VPV: 'desktop'
          })
        } else {
          gtm.trackEvent({
            event: 'vpv',
            VPV: 'age-gate'
          })
        }
        console.log('not old enough or on desktop')
      }
    })

    const acceptCookies = () => {
      cookiesAccepted.value = true
      Cookies.set('cookiesAccepted', true, { expires: 365 })
    }

    const onVideoEnded = () => {
      video.value.play()
      video.value.currentTime = 4
    }

    const enterAnimation = () => {
      const tl = anime.timeline({
        delay: 1000
      })

      tl.add(
        {
          targets: '.grid-front',
          opacity: [0, 1],
          easing: 'linear'
        },
        0
      )
        .add(
          {
            targets: '.hw-header',
            opacity: [0, 1],
            duration: 867,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          0
        )
        .add(
          {
            targets: background.value,
            opacity: [
              { value: 0, duration: 0 },
              { value: 1, duration: 1000, easing: 'linear' }
            ],
            // translateY: [
            //   { value: 180, duration: 0 },
            //   {
            //     value: 0,
            //     duration: 1067,
            //     easing: 'linear'
            //   }
            // ],
            scale: [
              { value: 1.3, delay: 400 },
              {
                value: 1,
                duration: 760,
                easing: 'cubicBezier(0.48, 0.02, 0.52, 0.98)'
              }
            ]
          },
          0
        )
        .add(
          {
            targets: '.home-age-gate-intro',
            opacity: [0, 1],
            duration: 567,
            easing: 'linear'
          },
          200
        )
        .add(
          {
            targets: '.age-gate-form-fields, .age-gate-form-field',
            borderColor: ['rgba(242,142,108,0)', 'rgba(242,142,108, 1)'],
            duration: 567,
            easing: 'linear'
          },
          300
        )
        .add(
          {
            targets: '.age-gate-form-field-value',
            opacity: [0, 1],
            duration: 867,
            delay: anime.stagger(100, { start: 1200 }),
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          300
        )
        .add(
          {
            targets: '.common-button.disabled',
            opacity: [0, 0.3],
            duration: 867,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          340
        )
        .add(
          {
            targets: '.home-legal',
            opacity: [0, 1],
            duration: 567,
            easing: 'linear'
          },
          500
        )
    }

    const onLeave = (el, done) => {
      if (!hasLeaveTransition.value) {
        done()
        return
      }
      soundTransition.play()
      const overlay = el.querySelector('.home-age-gate-overlay')

      nextTick(() => {
        anime.set('.intro-container', {
          display: 'none'
        })
        anime({
          targets: overlay,
          translateY: ['100%', '0%'],
          duration: 1433,
          easing: 'cubicBezier(0.81, 0, 0.13, 1)',
          complete: () => {
            overlay.style.transform = ''
            anime.set('.intro-container', {
              display: ''
            })
            done()
          }
        })
      })
    }

    return {
      acceptCookies,
      background,
      cookiesAccepted,
      enableCookiesBanner,
      isMobile,
      onLeave,
      onVideoEnded,
      root,
      router,
      t,
      video
    }
  }
}
