<template>
  <div class="home-footer">
    <div class="home-footer-logo">
      <HighWestLogo />
    </div>
    <div class="home-footer-qr">
      <img src="../assets/images/qr.png" alt="QR" />
    </div>
    <ul class="home-footer-links">
      <li>
        <a
          href="http://buy.highwest.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          BUY HIGH WEST
        </a>
      </li>
      <li>
        <a
          href="https://www.americanprairie.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          americanpraire.org
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import HighWestLogo from '@/assets/svg/high-west-logo.svg'

export default {
  components: {
    HighWestLogo
  }
}
</script>

<style lang="scss">
.home-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding: 0 42px 15px;
  display: flex;
  justify-content: space-between;

  background: #ffecd2;

  &-logo {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;

    svg {
      width: 151px;
      height: auto;
    }
  }

  &-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin: 0 4px 0 0;
    padding: 0;
    flex: 1;

    list-style: none;

    li {
      position: relative;
      margin: 0;
      padding: 0;

      &:not(:last-child) {
        margin-right: 87px;

        @include respond-to(md) {
          margin-right: 40px;
        }

        &::after {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -43px;
          opacity: 0.2;

          border-right: 1px solid #510020;
          content: '';

          @include respond-to(md) {
            right: -20px;
          }
        }
      }
    }

    a {
      color: #510020;

      font-family: font(heading);
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-decoration: none;
      transition: 0.25s ease;

      &:hover,
      &:focus {
        color: #9d1d2a;
      }
    }
  }

  &-qr {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -88px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 166px;

    background: #dc625c;
    border-radius: 20px;

    @include respond-to(md) {
      width: 120px;
      height: 120px;
    }

    img {
      width: 90px;
      height: auto;

      @include respond-to(md) {
        width: 75px;
      }
    }
  }

  @include respond-to-height(md) {
    .home-footer-qr {
      width: 126px;
      height: 126px;
      img {
        width: 60px;
      }
    }
  }
}
</style>
