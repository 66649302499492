
import { inject, ref, computed } from 'vue'
import CommonButton from '@/components/CommonButton.vue'
import { useRouter } from 'vue-router'
import { isDebugMode } from '@/hooks/global'

import moment from 'moment'
import Cookies from 'js-cookie'
import { useGtm } from 'vue-gtm'

export default {
  components: {
    'common-button': CommonButton
  },

  setup() {
    const t = inject('$t')
    const router = useRouter()
    const gtm = useGtm()

    const formError = ref(false)
    const day = ref(isDebugMode() ? '19' : '')
    const month = ref(isDebugMode() ? '12' : '')
    const year = ref(isDebugMode() ? '1991' : '')

    const isOver21 = computed(() => {
      if (day.value === '' || month.value === '' || year.value === '') return

      const birthdate = moment(
        `${year.value}-${month.value}-${day.value}`,
        'YYYY-MM-DD'
      )
      if (!birthdate.isValid()) return

      return birthdate.isBefore(moment().subtract(21, 'years'))
    })

    const checkForm = () => {
      formError.value = false
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'agegate-submit',
        eventLabel: '',
        eventValue: isOver21.value ? 1 : 0,
        eventCategory: 'cta'
      })
      if (!isOver21.value) {
        formError.value = true
        window.open('https://www.responsibility.org/', '_blank')
      } else {
        Cookies.set('over21', isOver21.value, { expires: 365 })

        if (isOver21.value) {
          setTimeout(() => {
            router.push({
              name: 'GameIntro'
            })
          }, 467)
        }
      }
    }

    return {
      t,
      day,
      month,
      year,
      checkForm,
      isOver21,
      formError
    }
  }
}
