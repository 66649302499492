import { template } from 'lol/js/string/template';

// TODO maybe implement available locale if needed ?

class _I18n {

  defaultLocale: string = 'en-us'
  locale: string = 'en-us'
  country: string = 'us'

  translations: Map<string, object>;

  constructor () {

    this.getTranslations = this.getTranslations.bind( this )
    this.country_code = this.country_code.bind( this )
    this.translate = this.translate.bind( this )
    this.match = this.match.bind( this )
    this.t = this.t.bind( this )

    this.locale = this.defaultLocale;
    this.translations = new Map<string, object>();

  }

  async setLocale( locale: string, redirect: boolean = false ): Promise<any> {

    if ( redirect ) {
      window.location.href = window.location.origin + "/" + locale;
      return;
    }

    // if ( window.location.pathname === '/' || window.location.pathname === '' ) {
    //   window.history.replaceState( null, document.title, "/" + locale + "/" + window.location.hash )
    // }

    if ( !this.translations.get( locale ) ) {
      let data = await fetch( "/locales/" + locale + ".json" );
      let t = await data.json();
      this.translations.set( locale, t );
    }

    document.body.classList.remove( this.locale );
    this.locale = locale;
    document.body.classList.add( this.locale );

    this.country = locale.split( "-" )[1];

    console.log( "[I18n] Locale loaded " + this.locale );
  }


  getTranslations() {
    return this.translations.get( this.locale );
  }


  translate( key: string, data: any = null ) {
    const translations = this.getTranslations()
    const item = translations[key]
    if ( !item ) return key
    if ( data != null ) return template( item, data )
    return item
  }

  replaceYouthGenerating( str: string ): string {
    return str.replace( /(Youth-Generating)/im, '<nobr>$1</nobr>' )
  }

  t( key: string, data: any = null ) {
    return this.translate( key, data )
  }

  match( regex: RegExp ) {
    const locales = {}
    const translations = this.getTranslations()
    Object.keys( translations ).forEach( ( key ) => {
      if ( key.match( regex ) ) {
        locales[key] = translations[key]
      }
    } )
    return locales
  }

  list( regex: RegExp ) {
    const list = []
    const locales = this.match( regex )
    Object.keys( locales ).forEach( ( key ) => {
      const match = key.match( new RegExp( /\[(\d+?)\]/ ) )
      list[match[1]] = locales[key]
    } )
    return list
  }

  group( regex: RegExp ) {
    const list = []
    const locales = this.match( regex )
    Object.keys( locales ).forEach( ( key ) => {
      const match = key.match( new RegExp( `${regex.source}.(\\w+)` ) )
      list[match[1]] = locales[key]
    } )
    return list
  }

  country_code() {
    const split = this.locale.split( '-' )
    if ( split.length == 2 ) {
      return split[1].toUpperCase()
    }

    return this.locale.toUpperCase()
  }

}

export const I18n = new _I18n()