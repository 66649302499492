<template>
  <div ref="animation" class="lottie-animation" />
</template>

<script>
import Lottie from 'lottie-web'
import { onMounted, ref } from '@vue/runtime-core'
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const animation = ref(null)
    const lottieAnim = ref(null)

    onMounted(() => {
      lottieAnim.value = Lottie.loadAnimation({
        container: animation.value,
        renderer: 'svg',
        loop: props.loop,
        autoplay: props.autoplay,
        path: props.url
      })
    })

    const play = () => {
      lottieAnim.value.play()
    }

    const stop = () => {
      lottieAnim.value.stop()
    }

    return {
      lottieAnim,
      animation,
      play,
      stop
    }
  }
}
</script>

<style lang="scss">
// .lottie-animation {
// }
</style>
