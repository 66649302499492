import { createStore, createLogger } from 'vuex'
import global from './global'
import game from './game'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    global,
    game
  },
  // plugins: [createLogger()]
})
