
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue'
import RotatePhone from '@/components/RotatePhone.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import MenuLayer from '@/components/MenuLayer.vue'
import store from '@/store'
import { checkIsMobile } from './hooks/global'
import { provideHowler } from './plugins/Howler'
import { Howler } from 'howler'
import { useRoute } from 'vue-router'
import timeout from './utils/timeout'
import { useGtm } from 'vue-gtm'

export default {
  name: 'App',
  components: {
    'site-header': SiteHeader,
    'rotate-phone': RotatePhone,
    'main-layer': MenuLayer
  },
  setup() {
    let howler = null
    const route = useRoute()
    const forceLandscape = ref(false)
    const gtm = useGtm()
    // const heightDebug = ref(0)

    const routeNeedLandscape = ref(false)
    const menuOpen = computed(() => store.getters['global/menuOpen'])
    const inGame = computed(() => store.getters['global/inGame'])
    const isFrontGridHidden = computed(
      () => store.getters['global/isFrontGridHidden']
    )
    const isMobile = computed(() => true || checkIsMobile())
    const orientation = computed(() => store.getters['global/getOrientation'])
    const triggeredResize = computed(
      () => store.getters['global/triggeredResize']
    )

    watch(
      () => route.meta,
      meta => {
        routeNeedLandscape.value = meta.landscape as boolean
      }
    )

    watch(triggeredResize, async () => {
      if (triggeredResize.value) {
        resize()
        store.commit('global/triggerResize', { triggeredResize: false })
      }
    })

    const orientationChange = (event, isInit = false) => {
      const isPortrait = !(
        window.orientation === -90 || window.orientation === 90
      )
      store.commit('global/setOrientation', isPortrait)
      if (!isInit)
        gtm.trackEvent({
          event: 'vpv',
          VPV: isPortrait ? 'gotoPortrait' : 'gotoLandscape'
        })
    }

    const resize = async () => {
      await timeout(300)
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      store.commit('global/setViewportSize', {
        width: window.innerWidth,
        height: window.innerHeight
      })
      // await timeout(200)
      // heightDebug.value = window.innerHeight
    }

    const visibilityChange = () => {
      if (document.hidden) {
        if (!howler.$muted && !howler.$tmpMuted) {
          howler.$toggleMute(true)
          howler.$tmpMuted = true
        }
      } else {
        if (howler.$tmpMuted && howler.$muted) {
          howler.$toggleMute(false)
          howler.$tmpMuted = false
        }
      }
    }

    onBeforeMount(() => {
      // if (process.env.VUE_APP_ENVIRONMENT === 'production') {
      //   window.location.replace('https://www.highwest.com/prairie-dash')
      //   return
      // }

      howler = provideHowler()

      const ua = window.navigator.userAgent
      const isFacebook =
        (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) &&
        ua.indexOf('MessengerLiteForiOS') < 0
      const isInsta = ua.indexOf('Instagram') > -1
      const isSnap = ua.indexOf('Snapchat') > -1
      // const isLinkedin = ua.indexOf('LinkedInApp') > -1
      forceLandscape.value = isFacebook || isInsta || isSnap
      // forceLandscape.value = true
      if (forceLandscape.value) store.commit('global/forceLandscape')
    })

    onMounted(() => {
      Howler.usingWebAudio = true
      Howler.mute(false)
      Howler.volume(1)

      // if (this.forceLandscape) {
      //   document.querySelector('html').classList.add('force-landscape')
      // }

      document.addEventListener('visibilitychange', visibilityChange, false)
      window.addEventListener('orientationchange', orientationChange)
      window.addEventListener('resize', resize)
      nextTick(() => {
        resize()
        orientationChange(null, true)
      })
    })

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', visibilityChange, false)
      window.removeEventListener('orientationchange', orientationChange)
      window.removeEventListener('resize', resize)
    })

    return {
      // heightDebug,
      forceLandscape,
      inGame,
      menuOpen,
      isFrontGridHidden,
      isMobile,
      orientation,
      routeNeedLandscape
    }
  }
}
