<template>
  <transition name="menu-layer" :css="false" @enter="onEnter" @leave="onLeave">
    <div class="menu-layer">
      <div class="menu-layer-overlay" />
      <div class="menu-layer-inner">
        <img
          src="../assets/images/pronghorn-bg-image.png"
          alt="Pronhorn"
          class="menu-layer-pronghorn"
        />
        <div class="menu-grid" />
        <div class="menu-layer-header">
          <lottie-animation
            ref="muteAnim"
            class="menu-layer-volume-icon"
            :class="{ muted: isMuted }"
            url="/assets/bodymovin/mute.json"
            :loop="false"
            :autoplay="false"
            @click="toggleSound"
          />

          <router-link to="/game-intro">
            <logo class="menu-layer-logo" />
          </router-link>

          <button class="t-btn menu-layer-button" @click="closeMenu">
            <span class="circle" />

            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cross"
            >
              <path
                d="M1.085 1.211L18.763 18.89M18.763 1.211L1.086 18.89"
                stroke="#510020"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="bevel"
              />
            </svg>
          </button>
        </div>

        <div class="menu-layer-primary-buttons">
          <div class="">
            <common-button
              v-if="active"
              class="main"
              :label="t('Home.cta-start')"
              to="/game"
              :appear="0"
              :tracking="{
                event: 'eventPush',
                eventAction: 'startgame',
                eventLabel: '',
                eventValue: '',
                eventCategory: 'menu'
              }"
            />
          </div>
          <div class="small-btns">
            <common-button
              v-if="active"
              :label="t('Menu.leaderboard-menu')"
              to="/leaderboard"
              outline
              small
              :appear="133"
              :tracking="{
                event: 'eventPush',
                eventAction: 'leaderboard',
                eventLabel: '',
                eventValue: '',
                eventCategory: 'menu'
              }"
              @click="closeMenu"
            />

            <common-button
              v-if="active"
              :label="t('Menu.about-menu')"
              to="/about"
              outline
              small
              :appear="133"
              :tracking="{
                event: 'eventPush',
                eventAction: 'about',
                eventLabel: '',
                eventValue: '',
                eventCategory: 'menu'
              }"
              @click="closeMenu"
            />
          </div>
        </div>

        <div class="menu-layer-share-container">
          <!-- <button type="button" class="t-btn-outline t-btn-icon" @click="share">
          <share-icon></share-icon> Spread the word
        </button> -->

          <common-button
            v-if="active"
            :label="t('Menu.share-menu')"
            outline
            small
            icon="share"
            :appear="99"
            :tracking="{
              event: 'eventPush',
              eventAction: 'share',
              eventLabel: '',
              eventValue: '',
              eventCategory: 'sharing'
            }"
            @click="share"
          />
        </div>

        <div class="menu-layer-external-links">
          <a
            class="menu-layer-external-link"
            href="http://highwest.com"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => trackEvent('visit-highwest.com')"
          >
            {{ t('Menu.menu-hw') }}
          </a>
          <a
            class="menu-layer-external-link"
            href="https://www.americanprairie.org/"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => trackEvent('visit-americanprairie.org')"
          >
            {{ t('Menu.menu-ap') }}
          </a>
        </div>

        <div class="menu-layer-legal-links">
          <a
            class="menu-layer-legal-link"
            href="/assets/pdf/rules.pdf"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => trackEvent('rules')"
          >
            {{ t('Menu.menu-rules') }}
          </a>

          <a
            class="menu-layer-legal-link"
            href="https://www.highwest.com/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => trackEvent('visit-termsandconditions')"
          >
            {{ t('Menu.menu-terms') }}
          </a>

          <a
            class="menu-layer-legal-link"
            href="https://www.highwest.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => trackEvent('visit-privacypolicy')"
          >
            {{ t('Menu.menu-privacy') }}
          </a>

          <a
            class="menu-layer-legal-link"
            href="https://www.highwest.com/do-not-sell-my-personal-information"
            target="_blank"
            rel="noopener noreferrer"
            @click="() => trackEvent('visit-donotsellmypersonnalinformation')"
          >
            {{ t('Menu.menu-nosell') }}
          </a>
        </div>

        <div class="menu-layer-footer">
          <router-link to="/submission" @click="trackSwipStake">
            <div>{{ t('Menu.altmain-menu') }}</div>
            <div class="main-menu-footer-link-sm">
              {{ t('Menu.alt-menu') }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {
  computed,
  inject,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watchEffect
} from 'vue'
import Logo from '@/assets/svg/high-west-logo.svg'
import anime from 'animejs/lib/anime.es.js'
import store from '@/store'
import CommonButton from '@/components/CommonButton.vue'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useGtm } from 'vue-gtm'

export default {
  components: {
    Logo,
    LottieAnimation,
    CommonButton
  },

  setup() {
    const t = inject('$t')
    const gtm = useGtm()
    const active = ref(false)
    const bars = ref(null)
    const muteAnim = ref(null)
    const howler = useHowler()
    const isMuted = ref(howler.$muted)

    const size = computed(() => store.getters['global/getViewportSize'])

    const sound = howler.$getSound('wouf', false, {
      srcs: [FX_URL + 'popup.mp3'],
      preload: true,
      html5: false,
      preloadCallback: () => {}
    })

    const toggleSound = () => {
      isMuted.value = howler.$toggleMute()

      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'mute',
        eventLabel: '',
        eventValue: isMuted.value ? 1 : 0,
        eventCategory: 'cta'
      })

      isMuted.value
        ? muteAnim.value.lottieAnim.playSegments([5, 30], true)
        : muteAnim.value.lottieAnim.playSegments([30, 60], true)
    }

    const share = () => {
      if (!navigator.share) {
        return
      }

      navigator.share({
        title: document.title,
        url: window.location.href
      })
    }

    const unwatch = watchEffect(() => {
      // console.log(store.getters['global/menuOpen'])
      // isOpen.value = store.getters['global/menuOpen']

      if (store.getters['global/menuOpen']) {
        active.value = store.getters['global/menuOpen']
      }
    })

    onMounted(() => {
      // onEnter()
    })

    onActivated(() => {
      // onEnter()
    })

    onDeactivated(() => {
      active.value = false
    })

    const closeMenu = () => {
      store.commit('global/setMenu', false)
      sound.play()
    }

    const onEnter = (el, done) => {
      const w = size.value.width - 40
      const h = size.value.height - 40
      var d = Math.sqrt(w * w + h * h)

      const gridLine = document.body.querySelector('.grid-front')

      gtm.trackEvent({
        event: 'vpv',
        VPV: 'menu'
      })

      const tl = anime.timeline({
        complete: () => {
          done()
        }
      })
      tl.add(
        {
          targets: el.querySelector('.menu-layer-inner'),

          keyframes: [
            { clipPath: 'circle(0px at 88vw 8vh)', duration: 0 }, // start frame
            {
              clipPath: `circle(${d}px at 88vw 8vh)`,
              duration: 867,
              easing: 'cubicBezier(0.30, 0.00, 0.26, 1.00)'
            } // end frame
          ]
        },
        100
      )
        .add(
          {
            targets: '.menu-layer-overlay',
            duration: 860,
            easing: 'cubicBezier(0.30, 0.00, 0.26, 1.00)',
            opacity: [0, 1]
          },
          100
        )
        .add(
          {
            targets: gridLine,
            duration: 800,
            easing: 'linear',
            borderColor: ['rgb(242,142,108)', 'rgba(81, 0, 32, 0.4)']
          },
          0
        )
        .add(
          {
            targets: '.circle',
            duration: 367,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            keyframes: [
              { opacity: 1, duration: 0 },
              {
                opacity: 0,
                duration: 367,
                easing: 'cubicBezier(0.33, 0, 0.67, 1)'
              }
            ],
            scale: [0, 1]
          },
          33
        )
        .add(
          {
            targets: '.cross',
            duration: 120,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            scale: [0, 1]
          },
          66
        )
        .add(
          {
            targets: '.menu-layer-external-link',
            duration: 333,
            delay: anime.stagger(33),
            easing: 'linear',
            opacity: [0, 1]
          },
          240
        )
        .add(
          {
            targets: '.menu-layer-legal-link',
            duration: 333,
            easing: 'linear',
            opacity: [0, 1]
          },
          333
        )
        .add(
          {
            targets: '.menu-layer-footer',
            duration: 1000,
            easing: 'cubicBezier(0.83, 0, 0.21, 1)',
            paddingBottom: [54, 24]
          },
          0
        )
    }

    const onLeave = (el, done) => {
      const gridLine = document.body.querySelector('.grid-front')

      const tl = anime.timeline({
        complete: () => {
          active.value = false
          done()
        }
      })
      tl.add(
        {
          targets: '.menu-layer-inner',
          duration: 700,
          easing: 'cubicBezier(0.34, 0, 0.34, 1)',
          clipPath: 'circle(0px at 89vw 5.5vh)'
        },
        0
      )
        .add(
          {
            targets: '.menu-layer-overlay',
            duration: 700,
            easing: 'cubicBezier(0.34, 0, 0.34, 1)',
            opacity: 0
          },
          0
        )
        .add(
          {
            targets: gridLine,
            duration: 800,
            easing: 'linear',
            borderColor: 'rgb(242,142,108)'
          },
          0
        )
        .add(
          {
            targets: '.circle',
            duration: 367,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            keyframes: [
              { opacity: 1, duration: 0 },
              {
                opacity: 0,
                duration: 367,
                easing: 'cubicBezier(0.33, 0, 0.67, 1)'
              }
            ],
            scale: [0, 1]
          },
          33
        )
        .add(
          {
            targets: '.cross',
            duration: 120,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            scale: 0
          },
          300
        )
    }

    const trackEvent = label => {
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: label,
        eventLabel: '',
        eventValue: '',
        eventCategory: 'menu'
      })
    }

    const trackSwipStake = () => {
      store.commit('game/setScore', { score: 0 })
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'entersweepstakes',
        eventLabel: 'Entering sweepstakes directly with menu',
        eventValue: '',
        eventCategory: 'navigation'
      })
    }

    return {
      active,
      closeMenu,
      onEnter,
      onLeave,
      trackEvent,
      trackSwipStake,
      share,
      bars,
      size,
      muteAnim,
      toggleSound,
      isMuted,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
.t-btn-primary.main::v-deep {
  .background {
    border-radius: 30px;

    @include respond-to(xs) {
      border-radius: 24px;
    }
  }
}

.menu-layer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0px;
  z-index: 5;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    width: 100%;
    height: 52px;
    margin: 15px 0 0;
    padding: 10px 15px;

    border-bottom: 1px solid rgba(color(dark-red), 0.4);

    a {
      font-size: 0px;
    }

    @include respond-to(xs) {
      margin: 12px 0 0;
      padding: 10px 12px;
      height: 38px;
    }
  }

  &-volume-icon {
    width: auto;
    max-width: 62px;
    height: 30px;
    position: absolute;
    left: 0px;

    @include respond-to(xs) {
      left: 0px;
      top: 8px;
      height: 24px;
    }
  }

  &-logo {
    width: auto;
    height: 18px !important;

    color: color(dark-red);
  }

  &-button {
    width: 62px;
    height: 52px;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    transition: 0.25s ease;

    .circle {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 2px solid rgba(color(dark-red), 0.4);
      transform-origin: center;
      opacity: 0;
    }

    @include respond-to(xs) {
      // width: 40px;
      height: 38px;
    }

    svg {
      width: 23px;
      height: 12px;

      line {
        transform-origin: right;
      }
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color: rgba(#510020, 0.3);
  }

  &-inner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color: color(pale-white);
    background-image: linear-gradient(
      to bottom,
      color(pale-white),
      color(white)
    );
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to(xs) {
      padding: 0 12px;
    }

    .t-btn-outline {
      @include respond-to(xs) {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: fontSize(sm);
      }
    }

    ul {
      display: flex;
      flex-direction: column;
    }
  }
  &.show {
    z-index: 4;
    .main-menu-inner {
      display: flex;
    }
  }

  &-primary-buttons {
    display: flex;
    // flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 50px 18px;

    border-bottom: 1px solid rgba(color(dark-red), 0.4);

    @include respond-to-height(sm) {
      padding: 38px 18px;
    }

    @include respond-to(xs) {
      padding: 30px 12px;
    }

    .common-button:not(:last-child) {
      margin-bottom: 16px;

      @include respond-to-height(sm) {
        margin-bottom: 16px;
      }

      @include respond-to(xs) {
        margin-bottom: 8px;
      }
    }

    .main {
      width: 125px;
      padding: 32px 20px;

      .background,
      .background-secondary {
        border-radius: 30px;
      }

      @include respond-to(xs) {
        padding: 30px 20px;
        width: 105px;
        border-radius: 20px;
      }
    }

    .t-btn-primary--small {
      padding: 15px 0px;
      display: block;
      width: 100%;
    }

    .small-btns {
      width: 100%;
      margin-left: 23px;

      @include respond-to(xs) {
        margin-left: 12px;
      }
    }
  }

  &-share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 32px 0;

    border-bottom: 1px solid rgba(color(dark-red), 0.4);

    @include respond-to-height(sm) {
      padding: 24px 0;
    }

    @include respond-to(xs) {
      padding: 12px 0;
    }
  }

  &-external-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 24px 0;

    border-bottom: 1px solid rgba(color(dark-red), 0.4);

    @include respond-to(xs) {
      padding: 11px 0;
    }
  }

  &-external-link {
    color: color(dark-red);

    font-size: fontSizeHeading(h6);
    font-family: font(heading);
    line-height: 1;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;

    @include respond-to(xs) {
      font-size: fontSize(sm);
    }

    &:not(:last-child) {
      margin-bottom: 20px;

      @include respond-to(xs) {
        margin-bottom: 10px;
      }
    }
  }

  &-legal-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    padding-bottom: 100px;

    // @include respond-to(xs) {
    //   padding: 7px 0;
    // }
  }

  &-legal-link {
    color: color(dark-red);

    font-size: fontSize(sm);
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    line-height: 1;

    @include respond-to(xs) {
      font-size: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 13px;

      @include respond-to(xs) {
        margin-bottom: 9px;
      }
    }
  }

  &-footer {
    margin-bottom: 15px;
    padding: 24px 0;

    background: color(dark-red);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;
    position: absolute;
    bottom: 0px;
    left: 15px;
    right: 15px;

    @include respond-to(xs) {
      margin-bottom: 12px;
      left: 12px;
      right: 12px;

      font-size: fontSize(sm);
    }

    a {
      color: color(pale-white);

      text-decoration: none;
    }

    &-link-sm {
      margin-top: 8px;

      font-size: fontSize(sm);
      text-decoration: underline;

      @include respond-to(xs) {
        margin-top: 5px;

        font-size: 9px;
      }
    }
  }

  &-pronghorn {
    position: absolute;
    left: 134px;
    top: 90px;
    z-index: 2;
    width: 114px;
    height: auto;
    pointer-events: none;

    @include respond-to-height(sm) {
      top: 80px;
      left: 136px;
      width: 110px;
    }

    @include respond-to(xs) {
      width: 90px;
      top: 60px;
      left: 106px;
    }
  }
}

.menu-grid {
  position: absolute;
  top: 15px;
  left: 15px;
  pointer-events: none;
  width: calc(100vw - 30px);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 30px);

  border: 1px solid color(dark-red);
  border-radius: 15px;
  opacity: 0.4;

  @include respond-to(xs) {
    top: 12px;
    left: 12px;
    width: calc(100vw - 24px);
    height: calc(var(--vh, 1vh) * 100 - 24px);
  }
}
</style>
