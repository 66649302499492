<template>
  <transition name="home" mode="out-in" @leave="onLeave">
    <div ref="root" class="home-container">
      <site-header v-if="isMobile"></site-header>
      <div class="home">
        <div v-if="isMobile" class="home-age-gate">
          <div class="wrapper">
            <div class="home-age-gate-intro">
              <p class="t-text--sm home-intro-copy">
                {{ t('AgeGate.main-ag') }}
              </p>
              <p
                class="t-text--sm home-intro-copy"
                v-html="t('AgeGate.sec-ag')"
              />
            </div>

            <age-gate-form></age-gate-form>

            <div class="home-spacer" />

            <div class="home-legal">
              <p class="t-text--xs" v-html="t('AgeGate.mainfooter-ag')" />
              <p class="t-text--xs" v-html="t('AgeGate.secfooter-ag')" />
            </div>

            <div
              v-if="!cookiesAccepted && enableCookiesBanner"
              class="home-cookie-banner"
            >
              <p class="t-text--sm home-cookie-banner-text">
                {{ t('AgeGate.cookies-ag') }}
              </p>
              <button class="home-cookie-banner-button" @click="acceptCookies">
                <check-icon></check-icon>
              </button>
            </div>
          </div>

          <div ref="background" class="home-age-gate-background" />
          <div class="home-age-gate-overlay" />
        </div>

        <div v-else class="home-desktop">
          <div class="home-video">
            <video
              ref="video"
              src="/assets/videos/home_loop_desktop.mp4#t=0.1"
              muted
              autoplay
              playsinline
              @ended="onVideoEnded"
            />
          </div>
          <div class="home-content">
            <img
              src="../assets/images/high-west-praire-dash-full-logo.png"
              alt="High West Praire Dash"
              class="home-full-logo"
            />

            <div class="home-visit-desc">
              <p>{{ t('Desktop.desc') }}</p>
            </div>

            <div class="home-visit-mobile-copy">
              <div>{{ t('Desktop.cta-top') }}</div>
              <div class="home-link-container">{{ t('Desktop.cta-url') }}</div>
              <div>{{ t('Desktop.cta-bottom') }}</div>
            </div>
          </div>
        </div>

        <home-footer v-if="!isMobile"></home-footer>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { inject, nextTick, onActivated, onMounted, ref } from 'vue'
import { checkIsMobile } from '../hooks/global'
import { useRouter } from 'vue-router'
import HomepageFooter from '@/components/HomepageFooter.vue'
import AgeGateForm from '@/components/AgeGateForm.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import CheckIcon from '@/assets/svg/check.svg'
import Cookies from 'js-cookie'
import anime from 'animejs/lib/anime.es.js'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'
import { useGtm } from 'vue-gtm'

// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    'home-footer': HomepageFooter,
    'age-gate-form': AgeGateForm,
    SiteHeader,
    CheckIcon
  },

  setup() {
    const gtm = useGtm()
    const t = inject('$t')
    const router = useRouter()
    const howler = useHowler()
    const isMobile = ref(false)
    const cookiesAccepted = ref(false)
    const enableCookiesBanner = ref(false)

    const root = ref(null)
    const background = ref(null)
    const video = ref(null)

    const hasLeaveTransition = ref(true)

    const soundTransition = howler.$getSound('transition-home', false, {
      srcs: [FX_URL + 'transition-home.mp3'],
      preload: true,
      preloadCallback: () => {},
      html5: false
    })

    onActivated(() => {
      isMobile.value = checkIsMobile()
      cookiesAccepted.value = !!Cookies.get('cookiesAccepted')

      nextTick(enterAnimation)

      const cookie = Cookies.get('over21')
      if (cookie === 'true' && isMobile.value) {
        hasLeaveTransition.value = false
        router.push({
          name: 'GameIntro'
        })
      } else {
        if (!isMobile.value) {
          gtm.trackEvent({
            event: 'vpv',
            VPV: 'desktop'
          })
        } else {
          gtm.trackEvent({
            event: 'vpv',
            VPV: 'age-gate'
          })
        }
        console.log('not old enough or on desktop')
      }
    })

    const acceptCookies = () => {
      cookiesAccepted.value = true
      Cookies.set('cookiesAccepted', true, { expires: 365 })
    }

    const onVideoEnded = () => {
      video.value.play()
      video.value.currentTime = 4
    }

    const enterAnimation = () => {
      const tl = anime.timeline({
        delay: 1000
      })

      tl.add(
        {
          targets: '.grid-front',
          opacity: [0, 1],
          easing: 'linear'
        },
        0
      )
        .add(
          {
            targets: '.hw-header',
            opacity: [0, 1],
            duration: 867,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          0
        )
        .add(
          {
            targets: background.value,
            opacity: [
              { value: 0, duration: 0 },
              { value: 1, duration: 1000, easing: 'linear' }
            ],
            // translateY: [
            //   { value: 180, duration: 0 },
            //   {
            //     value: 0,
            //     duration: 1067,
            //     easing: 'linear'
            //   }
            // ],
            scale: [
              { value: 1.3, delay: 400 },
              {
                value: 1,
                duration: 760,
                easing: 'cubicBezier(0.48, 0.02, 0.52, 0.98)'
              }
            ]
          },
          0
        )
        .add(
          {
            targets: '.home-age-gate-intro',
            opacity: [0, 1],
            duration: 567,
            easing: 'linear'
          },
          200
        )
        .add(
          {
            targets: '.age-gate-form-fields, .age-gate-form-field',
            borderColor: ['rgba(242,142,108,0)', 'rgba(242,142,108, 1)'],
            duration: 567,
            easing: 'linear'
          },
          300
        )
        .add(
          {
            targets: '.age-gate-form-field-value',
            opacity: [0, 1],
            duration: 867,
            delay: anime.stagger(100, { start: 1200 }),
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          300
        )
        .add(
          {
            targets: '.common-button.disabled',
            opacity: [0, 0.3],
            duration: 867,
            easing: 'cubicBezier(0.33, 0, 0.67, 1)'
          },
          340
        )
        .add(
          {
            targets: '.home-legal',
            opacity: [0, 1],
            duration: 567,
            easing: 'linear'
          },
          500
        )
    }

    const onLeave = (el, done) => {
      if (!hasLeaveTransition.value) {
        done()
        return
      }
      soundTransition.play()
      const overlay = el.querySelector('.home-age-gate-overlay')

      nextTick(() => {
        anime.set('.intro-container', {
          display: 'none'
        })
        anime({
          targets: overlay,
          translateY: ['100%', '0%'],
          duration: 1433,
          easing: 'cubicBezier(0.81, 0, 0.13, 1)',
          complete: () => {
            overlay.style.transform = ''
            anime.set('.intro-container', {
              display: ''
            })
            done()
          }
        })
      })
    }

    return {
      acceptCookies,
      background,
      cookiesAccepted,
      enableCookiesBanner,
      isMobile,
      onLeave,
      onVideoEnded,
      root,
      router,
      t,
      video
    }
  }
}
</script>

<style lang="scss">
.home-container {
  height: 100%;

  &.home-leave-to {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
  }
}

.home {
  height: 100%;
  overflow: hidden;

  &-desktop {
    min-height: 600px;
    height: 100%;
    position: relative;

    .home-video {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      video {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .home-content {
      height: calc(100% - 180px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    @include respond-to-height(md) {
      .home-visit-desc {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 3%;
        p {
          margin: 5px 0;
        }
      }
      .home-visit-mobile-copy {
        font-size: 16px;
        line-height: 16px;
      }
      .home-link-container {
        font-size: 20px;
        line-height: 25px;
      }
      .home-full-logo {
        height: 200px;
        margin: 3% auto 2%;
      }
    }
  }

  &-age-gate {
    position: relative;
    height: 100%;
    overflow: hidden;

    .wrapper {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 110px 30px 15px;

      @include respond-to(xs) {
        padding: 79px 20px 12px;
        background-position: bottom 50% center;
      }
    }

    &-background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: url(../assets/images/age-gate-bg.jpg);
      background-size: 100% auto;
      background-position: bottom 40% center;
      background-repeat: no-repeat;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: #e36b45;
      transform: translateY(100%);
    }
  }

  &-age-gate-intro {
    flex-grow: 0;
    padding: 68px 0;
    padding-bottom: 60px;
  }

  &-legal {
    margin-top: 32px;

    @include respond-to(xs) {
      margin-top: 20px;
    }

    .t-text--xs {
      @include respond-to(xs) {
        font-size: fontSize(xxs);
      }
    }
  }

  &-intro-copy {
    // padding: 40px 0;

    color: color(text-red);

    text-align: center;
    text-transform: uppercase;
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: 21px;
    }

    @include respond-to(xs) {
      // padding: 25px 0;

      font-size: 8px;
    }
  }

  &-age-gate-intro,
  &-legal {
    .t-text--xs {
      text-align: center;
    }
  }

  // &-age-gate-intro {
  //   display: flex;
  //   align-items: center;
  // }

  &-legal {
    // display: flex;
    // align-items: flex-end;
    padding-bottom: 14px;
  }

  &-full-logo {
    width: auto;
    height: 250px;
    margin: 50px auto 27px;
    transition: 0.25s ease;

    @include respond-to(md) {
      height: 150px;
      margin-bottom: 40px;
    }
  }

  &-visit-desc {
    color: #7d0f23;

    font-size: 22px;
    line-height: 33px;
    text-align: center;
    max-width: 680px;
    margin-bottom: 40px;

    @include respond-to(md) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &-visit-mobile-copy {
    color: #8c2534;

    font-size: 17px;
    line-height: 33px;
    text-align: center;
    text-transform: uppercase;

    @include respond-to(md) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &-link-container {
    padding: 7px 50px;
    margin: 8px auto;

    border: 1px solid #9d1d29;
    border-radius: 30px;
    color: #9d1d29;

    font-family: font(heading);
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 2px;
    user-select: all;

    @include respond-to(md) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &-cookie-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 32px 33px;
    background: #fff;
    color: #d8635f;
    position: absolute;
    bottom: 0;
    left: 0;

    // iPhone 5/SE
    // @include respond-to(xs) {
    //   margin: 0 -20px;
    //   padding: 12px 22px 28px;
    // }

    &-text {
      padding-right: 20px;

      line-height: 1.5;

      @include respond-to(xs) {
        font-size: 8px;
      }

      a {
        color: #d8635f;
      }
    }

    &-button {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;

      background: #d8635f;
      border: 0;
      border-radius: 50%;
      color: #fff;

      @include respond-to(xs) {
        width: 32px;
        height: 32px;
      }

      &:focus {
        outline: none;
      }

      svg {
        width: 18px;
        height: auto;
      }
    }
  }

  &-spacer {
    flex-grow: 1;
  }

  .home-legal a {
    color: black;
  }
}
</style>
