import { getEntries } from "@/utils/Entries"
import timeout from "@/utils/timeout"
import { ADD_LEVEL_JUNK, TOTAL_JUNK } from "@/webgl/game/gameConfig"

const gameStartState:any = { 
    isStarted: false,
    isLoaded: false,
    isGameStarted: false,
    isGameDone: false,
    isFail: false,
    isPaused: false,
    showGhost: false,
    // speed: 0,
    resourcesToLoad: null,
    totalResourcesToLoad: null,
    percSuccess: 0,
    countGesture:0,
    level:0,
    qteTransition: false,
    qteComing: -1,
    qte: -1,
    countQte: 0,
    cleanUp: 0,
    countdown: 0,
    posRunLeft: 0,
    posRunRight: 0,
    score: null,
    tempScore: 0,
    // time: 0,
    isInIntro:false,
    isInOutro:false,
    isResult: false,
    addScoreQte: false,
    bisonsPosition: []
}

export default {
    namespaced: true,
    state: () => ({
        ...gameStartState
    }),

    mutations: {
        //Global
        reset(state:any) {
            for (const [key, value] of Object.entries(state)) {
                state[key] = gameStartState[key]
            }
        },

        startStop: (state:any, args:any) => {
            state.isStarted = args.startStop
        },

        setLoader: (state:any, args:any) => {
            state.resourcesToLoad = args.toLoad

            if (state.totalResourcesToLoad == null) {
                state.totalResourcesToLoad = args.toLoad
            }
        },

        setShowGhost(state:any) {
            state.showGhost = true
        },

        togglePause(state:any) {
            state.isPaused = !state.isPaused
        },

        setLoaded: (state:any) => {
            state.isGameDone = false
            state.isLoaded = true
        },

        setUnLoaded: (state:any) => {
            state.isLoaded = false
            state.isInOutro = false
        },
        
        async setQTE(state:any, args:any) {
            if(state.qteTransition) return
            if(args.qte !== -1) state.countQte++
           state.qteTransition = true
           state.qteComing = args.qte
           await timeout(args.qte === -1 ? 1500 : 2500)
           state.isResult = false
           state.qte = args.qte
           await timeout(3000)
           state.qteTransition = false
        },

        addLevel(state:any) {
            state.level++
            if(state.level > 2) state.level = 0
        },

        setLevel(state:any, args:any) {
            state.level = args.level
        },
        
        // addToScore(state:any, args:any) {
        //     state.score += args.score;
        // },

        setScore(state:any, args:any) {
            state.score = args.score
        },

        setTempScore(state:any, args:any) {
            state.tempScore = args.score;
        },

        enableFail(state:any) {
            state.isFail = true;
        },

        disableFail(state:any) {
            state.isFail = false;
        },
        
        setIsIntro(state:any, args:any) {
            state.isInIntro = args.isInIntro
        },
        
        setIsOutro(state:any) {
            state.isInOutro = true
        },
        
        async setaddScoreQte(state:any) {
            state.addScoreQte = true
            await timeout(10)
            state.addScoreQte = false
        },

        //Running
        setPosRun: (state:any, args: any) => {
            if(args.isRight) state.posRunRight = args.pos
            else state.posRunLeft = args.pos
        },
        // setSpeed: (state:any, args: any) => {
        //     const speed = state.speed
        //     state.speed += args.speed * 0.001
        //     state.speed = Math.max(0, Math.min(1, state.speed))
        //     state.score += Math.round(Math.max(state.speed - speed, 0) * (0.5 + speed * 0.5) * 1000)
        // },
        
        // setConstantSpeed: (state:any, args: any) => {
        //     state.speed = Math.max(0, Math.min(1, args.speed))
        // },

        startGame: (state:any) => {
            state.isGameStarted = true
        },

        startTimer: (state:any) => {
            state.inOutro = false
            state.isGameDone = false
            // state.time = 61000
            state.score = 0
            // state.speed = 0
        },

        // setTimer: (state:any, args: any) => {
        //     state.time = args.time
        // },

        setGameDone: (state:any) => {
            state.isGameDone = true
        },

        setQteSuccess: (state:any, args: any) => {
            state.percSuccess = args.percSuccess;
        },

        // setSpeedAfterQte: (state:any) => {
        //     state.speed = 0.5
        // },

        addToCount: (state:any, args:any) => {
            state.countGesture = state.countGesture + args.count
        },

        resetCount: (state:any) => {
            state.countGesture = 0
        },

        //Cleaning
        resetCleanUp(state:any) {
            state.cleanUp = TOTAL_JUNK + ADD_LEVEL_JUNK * state.level
        },

        clickCleanUp(state:any) {
            state.cleanUp--
            state.cleanUp = Math.max(0, state.cleanUp)
        },

        // setSpeedAvoidJunk: (state:any) => {
        //     state.speed = 0.6
        // },

        setCountDown(state:any, args:any) {
            state.countdown = args.countdown
        },

        setToQteResult(state:any) {
            state.isResult = true
        },

        // Bisons
        setBisonsPosition(state:any, args:any) {
            state.bisonsPosition = args.bisonsPosition
        }

    },
    getters: {
        //Global
        isStarted: (state:any) => (state.isStarted),
        isGameStarted: (state:any) => (state.isGameStarted),
        isPaused: (state:any) => (state.isPaused),
        level: (state:any) => (state.level),
        showGhost: (state:any) => (state.showGhost),
        resourcesToLoad: (state:any) => (state.resourcesToLoad),
        progressResourcesLoaded: (state:any) => state.resourcesToLoad != null ? (state.resourcesToLoad / state.totalResourcesToLoad) : 1,
        isLoaded: (state:any) => (state.isLoaded),
        qteTransition: (state:any) => (state.qteTransition),
        qteComing: (state:any) => (state.qteComing),
        qte: (state:any) => (state.qte),
        countQte: (state:any) => (state.countQte),
        percSuccess: (state:any) => (state.percSuccess),
        isInIntro: (state:any) => (state.isInIntro),
        isInOutro: (state:any) => (state.isInOutro),
        score: (state:any) => (state.score),
        entries: (state:any) => {
            return getEntries(state.score)
        },
        tempScore: (state:any) => (state.tempScore),
        countdown: (state:any) => (state.countdown),
        isResultMode: (state:any) => (state.isResult),
        // gameTime: (state:any) => (state.time),
        isGameDone: (state:any) => (state.isGameDone),
        isFail: (state:any) => (state.isFail),
        addScoreQte: (state:any) => (state.addScoreQte),
        //Running
        posRunLeft: (state:any) => (state.posRunLeft),
        posRunRight: (state:any) => (state.posRunRight),
        // speed: (state:any) => (state.speed),
        countGesture: (state:any) => (state.countGesture),
        //CleanUp
        cleanUp: (state:any) => (state.cleanUp),
        //Bisons
        bisonsPosition: (state:any) => (state.bisonsPosition)
    }
  }