<template>
  <header class="hw-header">
    <logo />
  </header>
</template>

<script>
import Logo from '@/assets/svg/high-west-logo.svg'

export default {
  components: {
    logo: Logo
  }
}
</script>

<style lang="scss">
.hw-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  z-index: 5;

  border-bottom: 1px solid color(border);

  @include respond-to(xs) {
    height: 67px;
    top: 12px;
    left: 12px;
    right: 12px;
  }

  svg {
    width: auto;
    height: 33px;

    color: color(red);

    @include respond-to(xs) {
      height: 23px;
    }
  }
}
</style>
