
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Bugsnag from '@bugsnag/js' 
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { createGtm } from "vue-gtm";
import { I18n } from './core/I18n'


Bugsnag.start({ 
apiKey: '5b03b568a41d1233d2ddb8a32ee3295b', plugins: [new BugsnagPluginVue()] 
})

//Load locales before starting the app
I18n.setLocale('en', false).then(() => {
    const bugsnagVue = Bugsnag.getPlugin('vue')

    const app = createApp(App)

    app
        .use(store)
        .use(router)
        .use(bugsnagVue)
        .use(
            createGtm({
                id: "GTM-MPC3N4P", 
                debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
            })
          )
        .provide('$t', (key, data) => {
            return I18n.t(key, data)
        })
        .mount('#app')
})


