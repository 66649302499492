
const DEFAULT_DIR = '/assets/webgl';
const IMG_DIR = '/assets/images';

export const TEXTURE_URL = 'game/Assets/highwest-pronghorn-game/Textures/'
export const MUSIC_URL = '/assets/sounds/music/'
export const FX_URL = '/assets/sounds/ui/'

let _assetsDir = DEFAULT_DIR;
let _imgDir = IMG_DIR;

const Config = {
  
  
  /**
   * @param {string} path 
   * @return {string} prefixed path
   */
  asset_url(path:string):string{
    console.log('load asset', _assetsDir+'/'+path)
    return _assetsDir+'/'+path;
  },
  /**
   * @param {string} path 
   * @return {string} prefixed path
   */
  image_url(path:string):string{
    console.log('load image', _imgDir+'/'+path)
    return _imgDir+'/'+path;
  },

  setBaseDir( dir = DEFAULT_DIR ){
    _assetsDir = dir;
  },

  api( service:string ):string{
    return './apis/'+service;
  }

};


export default Config;