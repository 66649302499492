import { Howler, Howl } from 'howler'
import { inject, provide } from 'vue'
import anime from 'animejs/lib/anime.es.js'

const HowlerSymbol = Symbol()

let soundPool = []
let soundPoolGame = []
let isMuted = false
const volume = {
  value: 1
}

const getSound = (key, isGame = false) => {
  const pool = isGame ? soundPoolGame : soundPool
  const sounds = pool.filter(sound => sound.key === key)
  if (sounds.length === 1) return sounds[0]
  else if (sounds.length < 1) return null
  else {
    console.error('Sound loaded multiple time !!')
    return sounds[0]
  }
}
const loadSound = (key, isGame = false, callback) => {
  const sound = getSound(key, isGame)
  if (callback !== null) {
    sound.once('load', function() {
      callback(key)
    })
  }
  sound.load()
}

const createHowler = () => ({
  $muted: isMuted,
  $toggleMute(force) {
    const isForced = force !== undefined
    isMuted = isForced ? force : !isMuted

    if (isForced) {
      volume.value = isMuted ? 0 : 1
      Howler.volume(volume.value)
    } else {
      anime({
        targets: volume,
        duration: 1000,
        easing: 'linear',
        value: isMuted ? 0 : 1,
        update: () => {
          Howler.volume(volume.value)
        },
        complete: () => {
          Howler.mute(isMuted)
        }
      })
    }
    this.$muted = isMuted
    return isMuted
  },
  $getSound(
    key,
    isGame = false,
    {
      srcs = [],
      preload = false,
      preloadCallback = null,
      html5 = false,
      opts = {}
    } = {}
  ) {
    const sound = getSound(key, isGame)
    if (sound !== null) return sound
    else {
      const sound = new Howl({
        src: srcs,
        preload,
        html5,
        ...opts
      })
      sound.key = key
      const pool = isGame ? soundPoolGame : soundPool
      pool.push(sound)

      if (preload)
        sound.once('load', () => {
          preloadCallback(key)
        })

      // if (preload) loadSound(key, isGame, preloadCallback)

      return sound
    }
  },
  $unload(key) {
    getSound(key, true).unload()
    let toDelete = -1
    for (let i = 0; i < soundPoolGame.length; i++) {
      if (soundPoolGame[i].key === key) {
        toDelete = i
        break
      }
    }
    console.log('unload', key, toDelete)

    soundPoolGame.splice(toDelete, 1)
  },
  $unloadGame() {
    soundPoolGame.forEach(h => h.unload())
    soundPoolGame = []
  },
  $unloadAll() {
    soundPool = []
    soundPoolGame = []
    Howler.unload()
  }
})

export function provideHowler() {
  const howler = createHowler()

  provide(HowlerSymbol, howler)

  return howler
}

export function useHowler() {
  const howler = inject(HowlerSymbol)
  if (!howler) throw new Error(' no Howler plugin provided !!')

  return howler
}
