export default {
  namespaced: true,
  state: () => ({
    isPortrait: false,
    windowWidth: 0,
    windowHeight: 0,
    forceLandscape: false,
    inGame: false,
    isFrontGridHidden: false,
    howlerLayer1: null,
    triggeredResize: false,
    menuOpen: false,
    skipHomeLoader: false
  }),
  mutations: {
    setSkipHomeLoader: (state: any, v: boolean) => {
      state.skipHomeLoader = v
    },
    setOrientation: (state: any, isPortrait: boolean) => {
      state.isPortrait = isPortrait
    },
    setViewportSize: (state: any, viewportSize: any) => {
      state.windowWidth = viewportSize.width
      state.windowHeight = viewportSize.height
    },

    forceLandscape(state: any) {
      state.forceLandscape = true
    },

    disableForceLandscape(state: any) {
      state.forceLandscape = false
    },
    
    setMenu(state: any, v) {
      state.menuOpen = v
    },

    toggleInGame(state: any, args) {
      state.inGame = args.inGame
      state.isFrontGridHidden = args.inGame
      if(state.inGame) {
        
      }
    },

    toggleFrontGridVisibility(state: any, args) {
      state.isFrontGridHidden = args.isFrontGridHidden
    },

    //Sound
    setHowlerLayer1(state:any, args) {
      state.howlerLayer1 = args.howlerLayer1
    },

    triggerResize(state: any, args) {
      state.triggeredResize = args.triggeredResize
    }
  },
  getters: {
    skipHomeLoader: (state: any) => state.skipHomeLoader,
    getOrientation: (state: any) => state.isPortrait,
    getViewportSize: (state: any) => ({
      width: state.windowWidth,
      height: state.windowHeight
    }),
    forceLandscape: (state: any) => state.forceLandscape,
    inGame: (state: any) => state.inGame,
    isFrontGridHidden: (state: any) => state.isFrontGridHidden,
    howlerLayer1: (state: any) => state.howlerLayer1,
    triggeredResize: (state: any) => state.triggeredResize,
    menuOpen: (state: any) => state.menuOpen
  }
}
