
import {
  computed,
  inject,
  nextTick,
  onActivated,
  onDeactivated,
  onMounted,
  ref,
  watchEffect
} from 'vue'
import Logo from '@/assets/svg/high-west-logo.svg'
import anime from 'animejs/lib/anime.es.js'
import store from '@/store'
import CommonButton from '@/components/CommonButton.vue'
import { useHowler } from '@/plugins/Howler'
import { FX_URL } from '@/webgl/config'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useGtm } from 'vue-gtm'

export default {
  components: {
    Logo,
    LottieAnimation,
    CommonButton
  },

  setup() {
    const t = inject('$t')
    const gtm = useGtm()
    const active = ref(false)
    const bars = ref(null)
    const muteAnim = ref(null)
    const howler = useHowler()
    const isMuted = ref(howler.$muted)

    const size = computed(() => store.getters['global/getViewportSize'])

    const sound = howler.$getSound('wouf', false, {
      srcs: [FX_URL + 'popup.mp3'],
      preload: true,
      html5: false,
      preloadCallback: () => {}
    })

    const toggleSound = () => {
      isMuted.value = howler.$toggleMute()

      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'mute',
        eventLabel: '',
        eventValue: isMuted.value ? 1 : 0,
        eventCategory: 'cta'
      })

      isMuted.value
        ? muteAnim.value.lottieAnim.playSegments([5, 30], true)
        : muteAnim.value.lottieAnim.playSegments([30, 60], true)
    }

    const share = () => {
      if (!navigator.share) {
        return
      }

      navigator.share({
        title: document.title,
        url: window.location.href
      })
    }

    const unwatch = watchEffect(() => {
      // console.log(store.getters['global/menuOpen'])
      // isOpen.value = store.getters['global/menuOpen']

      if (store.getters['global/menuOpen']) {
        active.value = store.getters['global/menuOpen']
      }
    })

    onMounted(() => {
      // onEnter()
    })

    onActivated(() => {
      // onEnter()
    })

    onDeactivated(() => {
      active.value = false
    })

    const closeMenu = () => {
      store.commit('global/setMenu', false)
      sound.play()
    }

    const onEnter = (el, done) => {
      const w = size.value.width - 40
      const h = size.value.height - 40
      var d = Math.sqrt(w * w + h * h)

      const gridLine = document.body.querySelector('.grid-front')

      gtm.trackEvent({
        event: 'vpv',
        VPV: 'menu'
      })

      const tl = anime.timeline({
        complete: () => {
          done()
        }
      })
      tl.add(
        {
          targets: el.querySelector('.menu-layer-inner'),

          keyframes: [
            { clipPath: 'circle(0px at 88vw 8vh)', duration: 0 }, // start frame
            {
              clipPath: `circle(${d}px at 88vw 8vh)`,
              duration: 867,
              easing: 'cubicBezier(0.30, 0.00, 0.26, 1.00)'
            } // end frame
          ]
        },
        100
      )
        .add(
          {
            targets: '.menu-layer-overlay',
            duration: 860,
            easing: 'cubicBezier(0.30, 0.00, 0.26, 1.00)',
            opacity: [0, 1]
          },
          100
        )
        .add(
          {
            targets: gridLine,
            duration: 800,
            easing: 'linear',
            borderColor: ['rgb(242,142,108)', 'rgba(81, 0, 32, 0.4)']
          },
          0
        )
        .add(
          {
            targets: '.circle',
            duration: 367,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            keyframes: [
              { opacity: 1, duration: 0 },
              {
                opacity: 0,
                duration: 367,
                easing: 'cubicBezier(0.33, 0, 0.67, 1)'
              }
            ],
            scale: [0, 1]
          },
          33
        )
        .add(
          {
            targets: '.cross',
            duration: 120,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            scale: [0, 1]
          },
          66
        )
        .add(
          {
            targets: '.menu-layer-external-link',
            duration: 333,
            delay: anime.stagger(33),
            easing: 'linear',
            opacity: [0, 1]
          },
          240
        )
        .add(
          {
            targets: '.menu-layer-legal-link',
            duration: 333,
            easing: 'linear',
            opacity: [0, 1]
          },
          333
        )
        .add(
          {
            targets: '.menu-layer-footer',
            duration: 1000,
            easing: 'cubicBezier(0.83, 0, 0.21, 1)',
            paddingBottom: [54, 24]
          },
          0
        )
    }

    const onLeave = (el, done) => {
      const gridLine = document.body.querySelector('.grid-front')

      const tl = anime.timeline({
        complete: () => {
          active.value = false
          done()
        }
      })
      tl.add(
        {
          targets: '.menu-layer-inner',
          duration: 700,
          easing: 'cubicBezier(0.34, 0, 0.34, 1)',
          clipPath: 'circle(0px at 89vw 5.5vh)'
        },
        0
      )
        .add(
          {
            targets: '.menu-layer-overlay',
            duration: 700,
            easing: 'cubicBezier(0.34, 0, 0.34, 1)',
            opacity: 0
          },
          0
        )
        .add(
          {
            targets: gridLine,
            duration: 800,
            easing: 'linear',
            borderColor: 'rgb(242,142,108)'
          },
          0
        )
        .add(
          {
            targets: '.circle',
            duration: 367,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            keyframes: [
              { opacity: 1, duration: 0 },
              {
                opacity: 0,
                duration: 367,
                easing: 'cubicBezier(0.33, 0, 0.67, 1)'
              }
            ],
            scale: [0, 1]
          },
          33
        )
        .add(
          {
            targets: '.cross',
            duration: 120,
            easing: 'cubicBezier(0.4, 0, 0.83, 1)',
            scale: 0
          },
          300
        )
    }

    const trackEvent = label => {
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: label,
        eventLabel: '',
        eventValue: '',
        eventCategory: 'menu'
      })
    }

    const trackSwipStake = () => {
      store.commit('game/setScore', { score: 0 })
      gtm.trackEvent({
        event: 'eventPush',
        eventAction: 'entersweepstakes',
        eventLabel: 'Entering sweepstakes directly with menu',
        eventValue: '',
        eventCategory: 'navigation'
      })
    }

    return {
      active,
      closeMenu,
      onEnter,
      onLeave,
      trackEvent,
      trackSwipStake,
      share,
      bars,
      size,
      muteAnim,
      toggleSound,
      isMuted,
      t
    }
  }
}
