export const SPEED_RUN:number = 0.4
export const SPEED_MULT:number = 2

export const GAME_GLOBAL_SPEED_SCALE:number = 7

export const SPEED_SLOWMO:number =  0.06

export const BACK_SLOWMO:number = 0.06
export const BACK_FENCE_SLOWMO:number = 0.03

export const RESULT_TIME = 2

export const envs = {
    SUN_POS: -0.09
}

//Clean up settings
export const CLEANUP_TIME = 5
export const TOTAL_JUNK = 25
export const ADD_LEVEL_JUNK = 10


//Dodge settings
export const DODGE_TIME = 3
export const DODGE_PENCIL_SIZE:number = 44
export const DODGE_ALPHA_THRESHOLD: number = 50
export const DODGE_CANVAS_SIZE: number = 512
export const DODGE_NB_GLYPHS: number = 3
export const DODGE_START_POS = [
    {
        x: 0.26,
        y: 0.32,
        rot: -5
    },

    {
        x: 0.2,
        y: 0.58,
        rot: 216
    },

    {
        x: 0.32,
        y: 0.28,
        rot: 14
    }
]

//Bisons settings
export const CLICK_START_TIME: number = 0.1
export const CLICK_END_TIME: number = 1
export const TARGET_SPEED: number = 0.8