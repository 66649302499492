<template>
  <div
    class="wrapper"
    :class="{
      'is-portrait': orientation,
      'is-landscape': !orientation,
      'force-landscape': forceLandscape && routeNeedLandscape
    }"
  >
    <div class="wrapper-content">
      <div class="wrapper-content-article">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
    <!-- <main-menu v-if="isMobile && !inGame" /> -->

    <div v-if="!inGame && !isFrontGridHidden" class="grid-front" />
    <rotate-phone v-show="!forceLandscape" />
    <main-layer v-show="menuOpen" />
    <!-- <div class="debug">Height: {{ heightDebug }}</div> -->
  </div>
</template>

<script lang="ts">
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue'
import RotatePhone from '@/components/RotatePhone.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import MenuLayer from '@/components/MenuLayer.vue'
import store from '@/store'
import { checkIsMobile } from './hooks/global'
import { provideHowler } from './plugins/Howler'
import { Howler } from 'howler'
import { useRoute } from 'vue-router'
import timeout from './utils/timeout'
import { useGtm } from 'vue-gtm'

export default {
  name: 'App',
  components: {
    'site-header': SiteHeader,
    'rotate-phone': RotatePhone,
    'main-layer': MenuLayer
  },
  setup() {
    let howler = null
    const route = useRoute()
    const forceLandscape = ref(false)
    const gtm = useGtm()
    // const heightDebug = ref(0)

    const routeNeedLandscape = ref(false)
    const menuOpen = computed(() => store.getters['global/menuOpen'])
    const inGame = computed(() => store.getters['global/inGame'])
    const isFrontGridHidden = computed(
      () => store.getters['global/isFrontGridHidden']
    )
    const isMobile = computed(() => true || checkIsMobile())
    const orientation = computed(() => store.getters['global/getOrientation'])
    const triggeredResize = computed(
      () => store.getters['global/triggeredResize']
    )

    watch(
      () => route.meta,
      meta => {
        routeNeedLandscape.value = meta.landscape as boolean
      }
    )

    watch(triggeredResize, async () => {
      if (triggeredResize.value) {
        resize()
        store.commit('global/triggerResize', { triggeredResize: false })
      }
    })

    const orientationChange = (event, isInit = false) => {
      const isPortrait = !(
        window.orientation === -90 || window.orientation === 90
      )
      store.commit('global/setOrientation', isPortrait)
      if (!isInit)
        gtm.trackEvent({
          event: 'vpv',
          VPV: isPortrait ? 'gotoPortrait' : 'gotoLandscape'
        })
    }

    const resize = async () => {
      await timeout(300)
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      store.commit('global/setViewportSize', {
        width: window.innerWidth,
        height: window.innerHeight
      })
      // await timeout(200)
      // heightDebug.value = window.innerHeight
    }

    const visibilityChange = () => {
      if (document.hidden) {
        if (!howler.$muted && !howler.$tmpMuted) {
          howler.$toggleMute(true)
          howler.$tmpMuted = true
        }
      } else {
        if (howler.$tmpMuted && howler.$muted) {
          howler.$toggleMute(false)
          howler.$tmpMuted = false
        }
      }
    }

    onBeforeMount(() => {
      // if (process.env.VUE_APP_ENVIRONMENT === 'production') {
      //   window.location.replace('https://www.highwest.com/prairie-dash')
      //   return
      // }

      howler = provideHowler()

      const ua = window.navigator.userAgent
      const isFacebook =
        (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) &&
        ua.indexOf('MessengerLiteForiOS') < 0
      const isInsta = ua.indexOf('Instagram') > -1
      const isSnap = ua.indexOf('Snapchat') > -1
      // const isLinkedin = ua.indexOf('LinkedInApp') > -1
      forceLandscape.value = isFacebook || isInsta || isSnap
      // forceLandscape.value = true
      if (forceLandscape.value) store.commit('global/forceLandscape')
    })

    onMounted(() => {
      Howler.usingWebAudio = true
      Howler.mute(false)
      Howler.volume(1)

      // if (this.forceLandscape) {
      //   document.querySelector('html').classList.add('force-landscape')
      // }

      document.addEventListener('visibilitychange', visibilityChange, false)
      window.addEventListener('orientationchange', orientationChange)
      window.addEventListener('resize', resize)
      nextTick(() => {
        resize()
        orientationChange(null, true)
      })
    })

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', visibilityChange, false)
      window.removeEventListener('orientationchange', orientationChange)
      window.removeEventListener('resize', resize)
    })

    return {
      // heightDebug,
      forceLandscape,
      inGame,
      menuOpen,
      isFrontGridHidden,
      isMobile,
      orientation,
      routeNeedLandscape
    }
  }
}
</script>

<style lang="scss">
@import './styles/main.scss';

#app > .wrapper {
  display: flex;
  min-height: calc(var(--vh, 1vh) * 100);
}

.wrapper-content {
  position: relative;
  width: 100%;
  // padding: 15px;
  html.game & {
    padding: 0;
  }
  &-article {
    width: 100%;
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);

    // height: calc(var(--vh, 1vh) * 100 - 30px - 100px);
    html.game & {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }
}

.grid-front {
  position: absolute;
  top: 15px;
  left: 15px;
  pointer-events: none;
  width: calc(100vw - 30px);
  height: calc(var(--vh, 1vh) * 100 - 30px);
  z-index: 5;
  // height: 100vh;
  border: 1px solid color(border);
  border-radius: 15px;

  .is-landscape & {
    height: calc(var(--vh, 1vh) * 100 - 30px);
  }

  @include respond-to(xs) {
    top: 12px;
    left: 12px;
    width: calc(100vw - 24px);
    // height: calc(100% - 24px);

    .is-landscape & {
      height: calc(var(--vh, 1vh) * 100 - 24px);
    }
  }
}

// Force landscape
.wrapper.force-landscape {
  transform: rotate(90deg) translate(0px, -100%) !important;
  width: 100vh !important;
  height: 100vw !important;
  min-height: 100% !important;
  transform-origin: 0 0 !important;

  .grid-front {
    width: calc(var(--vh, 1vh) * 100 - 30px) !important;
    height: calc(100vw - 30px) !important;
  }
}

.debug {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  font-size: 50px;
  color: red;
}
</style>
