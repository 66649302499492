<template>
  <transition name="rotate" :css="false" @enter="onEnter">
    <div v-show="show" class="rotate-phone">
      <high-west-logo v-if="isPortrait" />
      <div class="rotate-phone-content">
        <p>{{ t('Rotate.main-rotate') }} {{ t('Rotate.sub-rotate') }}</p>

        <rotate-device-h v-if="isPortrait" />
        <rotate-device-v v-else />
      </div>
      <div />
    </div>
  </transition>
</template>

<script lang="ts">
import {
  inject,
  computed,
  onMounted,
  ref,
  watch,
  onActivated,
  onUpdated,
  nextTick
} from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import HighWestLogo from '@/assets/svg/high-west-logo.svg'
import RotateDeviceV from '@/assets/svg/rotate-device-verticaly.svg'
import RotateDeviceH from '@/assets/svg/rotate-device-horizontally.svg'
import anime from 'animejs/lib/anime.es.js'

const IS_LANDSCAPE = ['/game', '/score']

export default {
  components: {
    HighWestLogo,
    RotateDeviceV,
    RotateDeviceH
  },

  setup() {
    const t = inject('$t')
    const show = ref(false)
    const portraitAllowed = ref(true)
    const isPortrait = ref(false)
    let tl = null

    let _next = null

    const route = useRoute()
    const router = useRouter()

    router.beforeEach((to, from, next) => {
      portraitAllowed.value = !(to.meta.landscape as boolean)
      checkShow()
      if (
        !show.value ||
        (to.meta.landscape === undefined && !from.meta.landscape)
      ) {
        _next = null
        next()
      } else {
        _next = next
      }
    })

    // watch(
    //   () => route.meta,
    //   meta => {
    //     portraitAllowed.value = !(meta.landscape as boolean)
    //     checkShow()
    //   }
    // )

    const getOrientation = computed(() => {
      return (<any>store).getters['global/getOrientation']
    })

    const checkShow = () => {
      show.value = store.getters['global/forceLandscape']
        ? false
        : isPortrait.value !== portraitAllowed.value
    }

    const onEnter = el => {
      tl && tl.remove()
      const rotatePhoneContent = el.querySelector('.rotate-phone-content')
      const text = rotatePhoneContent.querySelector('p')
      const svg = rotatePhoneContent.querySelector('svg')
      tl = anime.timeline({
        delay: 0,
        complete: () => {
          _next && _next()
        }
      })
      tl.add(
        {
          targets: el,
          easing: 'cubicBezier(0.82, 0, 0.3, 1)',
          keyframes: [
            {
              clipPath: isPortrait.value
                ? 'inset(0px 0px 0px 100vw)'
                : `inset(0px 0px ${window.innerHeight}px 0vw)`,
              duration: 0
            }, // start frame
            {
              clipPath: isPortrait.value
                ? 'inset(0px 0px 0px 0vw)'
                : 'inset(0px 0px 0px 0vw)',
              duration: 633
            } // end frame
          ]
        },
        0
      )
        .add(
          {
            targets: text,
            duration: 800,
            easing: 'cubicBezier(0.82, 0, 0.3, 1)',
            translateX: isPortrait.value ? [-60, 0] : 0,
            translateY: !isPortrait.value ? [-60, 0] : 0
          },
          0
        )
        .add(
          {
            targets: svg,
            duration: 800,
            easing: 'cubicBezier(0.82, 0, 0.3, 1)',
            translateX: isPortrait.value ? [-60, 0] : 0,
            translateY: !isPortrait.value ? [60, 0] : 0
          },
          0
        )
    }

    watch(getOrientation, orientation => {
      isPortrait.value = orientation
      checkShow()
    })

    return {
      getOrientation,
      isPortrait,
      show,
      portraitAllowed,
      t,
      onEnter
    }
  }
}
</script>

<style lang="scss">
.rotate-phone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 52px 16px;
  z-index: 10;

  background: color(pale-white);
  color: color(light-red);

  font-family: font(heading);
  font-size: fontSizeHeading(h3);
  text-transform: uppercase;

  svg {
    width: auto;
    height: 27px;
  }

  p {
    max-width: 270px;
    margin: 0 auto;

    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    svg {
      margin-top: 36px;
      width: 125px;
      height: 125px;
    }
  }
}
</style>
