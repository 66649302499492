import store from '@/store'
import { computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export const isDebugMode = (): boolean => {
  const router = useRouter()
  return router.currentRoute.value.query.debug === '1'
}

export const isAutoRunMode = (): boolean => {
  const router = useRouter()
  return router.currentRoute.value.query.autoRun === '1'
}

export const hasFakeScore = (): string|string[]|boolean => {
  const router = useRouter()
  return router.currentRoute.value.query.score !== undefined ? router.currentRoute.value.query.score : false
}

export const checkIsMobile = (): boolean => {
  const isDesktopMQ = window.matchMedia(
    'only screen and (pointer: coarse)'
  )

  return isDesktopMQ.matches
}

//Check if the over21 cookie is set and true and if we're on mobile, otherwise, back to home page
export const checkPage = (): boolean => {
  const router = useRouter()
  const route = useRoute()
  const cookie = getCookie('over21')

  if (cookie === 'true' && checkIsMobile())
    console.log('Age gate cookie good, mobile good')
  else {
    const query = route.query
    router.push({
      name: 'Home',
      query
    })}

  return cookie === 'true' && checkIsMobile()
}

export const getCookie = (name: String): string | undefined => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2)
    return parts
      ?.pop()
      ?.split(';')
      ?.shift()
  return parts.join()
}

export const watchResize = (callback: any) => {
  const viewportSize = computed(() => {
    return (<any>store).getters['global/getViewportSize']
  })

  const unwatch = watch(viewportSize, vps => {
    callback(vps.width, vps.height, store.getters['global/getOrientation'])
  })

  onMounted(() => {
    const vps = viewportSize.value
    callback(vps.width, vps.height)
  })

  return unwatch
}

export const orientationChange = (isPortrait: any) => {
  if (window.orientation === -90 || window.orientation === 90)
    isPortrait.value = false
  else isPortrait.value = true
}
